import React from "react";
import Modal from "react-bootstrap/Modal";
import ReactLoading from "react-loading";

function VerificationModal(props) {
    const { show, onHide, onConfirm, isLoading } = props;

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="delete-user-modal"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="delete-user-modal">
                    Confirmation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to Proceed with this task?</p>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <button className="m-0 mx-2 red-btn btn" onClick={onHide}>
                    No
                </button>
                <button className="m-0 mx-2 green-btn btn" onClick={onConfirm} disabled={isLoading}>
                    {isLoading ? (
                        <ReactLoading className="loader" type="spokes" />
                    ) : (
                        "Yes"
                    )}
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default VerificationModal;
