import { useState, useEffect, useContext, createContext } from "react";
import { auth } from "../firebase";
import { toast } from "react-toastify";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [isReader, setIsReader] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdTokenResult();
        setIsAdmin(token.claims.admin);
        setIsClient(token.claims.client);
        setIsReader(token.claims.reader);

        // Set the displayName from the token
        if (token.claims.name) {
          user.displayName = token.claims.name;
        }
      }
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const logout = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      toast.error(`Error signing out: ${error.message}`);
    }
  };

  const value = { currentUser, logout, isAdmin, isClient, isReader };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
