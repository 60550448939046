import React, { useState } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";
import Modal from "react-bootstrap/Modal";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import "react-toastify/dist/ReactToastify.css";

function AddUserModal(props) {
    const {
        addUserModalOpen,
        setAddUserModalOpen,
        currentUser,
        setIsLoading,
        isLoading,
        fetchUsers
    } = props;

    // States
    const [email, setEmail] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [password, setPassword] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [isClient, setIsClient] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    // Firebase imports
    const createUser = httpsCallable(functions, "createUser");

    // Create new user
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!currentUser || !currentUser.isAdmin) {
            toast.error("Only admins can create users");
            return;
        }

        try {
            setIsLoading(true);
            await createUser({ email, password, displayName, isAdmin, isClient });
            toast.success("New user account created: " + displayName);
            setEmail("");
            setDisplayName("");
            setPassword("");
            await fetchUsers();
        } catch (error) {
            const errorCode = error?.details?.code || error.code;
            switch (errorCode) {
                case "auth/email-already-in-use":
                    toast.error("Error: This email is already in use.");
                    break;
                case "auth/username-already-exists":
                    toast.error("Error: This username is already taken.");
                    break;
                default:
                    toast.error("Error creating new user account: " + error.message);
            }
        } finally {
            setAddUserModalOpen(false);
            setIsLoading(false);
        }
    };

    return (
        <Modal
            show={addUserModalOpen}
            onHide={() => setAddUserModalOpen(false)}
            aria-labelledby="add-user-modal"
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="add-user-modal">
                    Add New User
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div className="my-3">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            className="form-control form-control-lg"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            autoComplete="email"
                        />
                    </div>
                    <div className="my-3">
                        <label htmlFor="displayName">Username:</label>
                        <input
                            type="text"
                            id="displayName"
                            className="form-control form-control-lg"
                            value={displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                            required
                            autoComplete="displayName"
                        />
                    </div>
                    <div className="my-3 form-group position-relative">
                        <label htmlFor="password">Temporary Password:</label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            className="form-control form-control-lg"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            autoComplete="new-password"
                        />
                        <span
                            className="password-toggle-icon"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            <FontAwesomeIcon
                                icon={showPassword ? faEye : faEyeSlash}
                                color="#212529"
                            />
                        </span>
                    </div>
                    <div className="my-3">
                        <label style={{ cursor: "pointer", width: "fit-content" }}>
                            <input
                                className="mx-2 w-auto"
                                type="radio"
                                name="role"
                                value="admin"
                                checked={isAdmin}
                                onChange={() => {
                                    setIsAdmin(true);
                                    setIsClient(false);
                                }}
                                required
                            />
                            Admin
                        </label>
                        <label style={{ cursor: "pointer", width: "fit-content" }}>
                            <input
                                className="mx-2 w-auto"
                                type="radio"
                                name="role"
                                value="client"
                                checked={isClient}
                                onChange={() => {
                                    setIsClient(true);
                                    setIsAdmin(false);
                                }}
                                required
                            />
                            Client
                        </label>
                    </div>
                    <button className="green-btn btn" type="submit" disabled={isLoading}>
                        {isLoading ? (
                            <ReactLoading className="loader" type="spokes" />
                        ) : (
                            "Create User"
                        )}
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default AddUserModal;
