import { getFunctions, httpsCallable } from "firebase/functions";

export const fetchBannedIPs = async () => {
    try {
        const functions = getFunctions();
        const getBannedIPsCallable = httpsCallable(functions, "getBannedIPs");
        const result = await getBannedIPsCallable();
        return result.data;
    } catch (error) {
        throw new Error(`Failed to fetch banned IPs. Error: ${error.message}`);
    }
};
