import React, { useState, useEffect } from "react";
import "../_styles.scss";
import { doc, deleteDoc, getDocs, collection, getDoc } from "firebase/firestore";
import { ref, deleteObject, getStorage } from "firebase/storage";
import { db } from "../firebase";
import useProtected from "../hooks/useProtected";
import { toast } from "react-toastify";
import AddPortfolioModal from "./AddPortfolioModal";
import ItemBox from "../components/ItemBox";

const UserPortfolio = () => {
    useProtected();
    const [displayName, setDisplayName] = useState(null);
    const [docsData, setDocsData] = useState([]);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDelete = async (docId) => {
        const docRef = doc(db, displayName, docId);

        try {
            // Try to get the Firestore document
            const snapshot = await getDoc(docRef);
            if (snapshot.exists()) {
                // If the document exists, then get the data
                const data = snapshot.data();

                // Iterate over each file in the document data
                for (let key in data) {
                    if (key.startsWith("fileName")) {
                        const fileName = data[key];
                        const fileRef = ref(getStorage(), `${displayName}/${docId}/files/${fileName}`);
                        // Delete the file from storage
                        await deleteObject(fileRef);
                    }
                }

                // Delete the Firestore document
                await deleteDoc(docRef);
                toast.success("Document and files deleted successfully!");

                // Refetch the documents
                setDocsData(prev => prev.filter(doc => doc.id !== docId));
            }
        } catch (error) {
            toast.error("Error deleting document and files:", error);
            console.error("Error deleting document and files:", error);
        }
    };

    const handleEdit = (docId, updatedData) => {
        setDocsData(prev => prev.map(doc => doc.id === docId ? updatedData : doc));
    };

    const DocList = ({ docs, handleDelete, handleEdit }) => {  // Added handleEdit here
        return (
            <div className="p-0 py-5 m-0 row w-100 mx-auto">
                <div className="p-0 m-0 w-100 mx-auto text-center justify-content-center">
                    <div className="p-4 m-0 portfolio-container">
                        {docs.map((doc, index) => {
                            return (
                                <ItemBox key={doc.id} data={doc} onDelete={handleDelete} handleEdit={handleEdit} /> // Added handleEdit here
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };


    useEffect(() => {
        const fetchDocs = async () => {
            const userCollectionRef = collection(db, displayName);
            const userDocsSnapshot = await getDocs(userCollectionRef);
            const documents = userDocsSnapshot.docs
                .filter(doc => doc.data().mainTitle)
                .map(doc => ({ id: doc.id, ...doc.data() }));
            setDocsData(documents);
        };

        if (displayName) fetchDocs();
    }, [displayName]);

    return (
        <>
            <div className="portfolio-add-btn btn">
                <button className="green-btn btn" onClick={handleShow}>
                    Add New Portfolio
                </button>
            </div>
            <div className="p-0 m-0 col-12 row">
                <AddPortfolioModal show={show} handleClose={handleClose} setDisplayName={setDisplayName} setDocsData={setDocsData} />
                <DocList docs={docsData} handleDelete={handleDelete} handleEdit={handleEdit} />
            </div>
        </>
    );
};

export default UserPortfolio;
