import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import "../_styles.scss";
import EditPortfolioModal from "./EditPortfolioModal";

function ItemBox({ path, data, onDelete, handleEdit }) {
    const placeholder = "./images/placeholder-16-9.jpg";
    const [isLoading, setIsLoading] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [currentData, setCurrentData] = useState({});

    const handleDelete = async (id) => {
        setIsLoading(true);
        await onDelete(id);
        setIsLoading(false);
    };

    const openEditModal = (data) => {  // Renamed this function to avoid redeclaration of 'handleEdit'
        setCurrentData(data);
        setShowEdit(true);
    };

    const handleClose = (updatedData) => {
        setShowEdit(false);
        if (updatedData) {
            setCurrentData(prev => ({ ...prev, ...updatedData }));
        }
    };

    const image = data.fileUrl0;
    const mainTitle = data.mainTitle;
    const mainDesc = data.mainDesc;
    const id = data.id;

    return (
        <div className="custom-card portfolio-item">
            <Link to={path}>
                <div className="img-container">
                    <img
                        className="card-img-top"
                        src={image || placeholder}
                        alt={mainTitle || "Placeholder image"}
                        title={mainTitle}
                    />
                </div>
            </Link>
            <div className="card-body">
                <h5 className="card-title color4" dangerouslySetInnerHTML={{ __html: mainTitle || "No Title" }}></h5>
                <p className="card-text" dangerouslySetInnerHTML={{ __html: mainDesc || "No Description" }}></p>
                <div className="d-flex justify-content-between">
                    <button onClick={() => openEditModal(data)} className="btn blue-btn w-100 m-0 my-1">Edit</button>
                    <EditPortfolioModal show={showEdit} handleClose={handleClose} currentData={currentData} handleEdit={handleEdit} />
                    <button className="btn w-100 m-0 my-1 red-btn" onClick={() => handleDelete(id)} disabled={isLoading}>
                        {isLoading ? (
                            <ReactLoading className="loader" type="spokes" />
                        ) : (
                            "Delete"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ItemBox;
