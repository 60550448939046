import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../_styles.scss";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";

function Header() {
    const [expanded, setExpanded] = useState(false);
    const navRef = useRef();

    const handleNavClick = () => {
        if (window.innerWidth <= 768) {
            setExpanded(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setExpanded(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className="the-border-1" />
            <div className="col-12 m-0 p-0">
                <div className="col-12 align-items-center justify-content-center d-lg-flex">
                    <div className="p-0 m-0 col-12 col-lg-8 text-center" ref={navRef}>
                        <Navbar expand="md" expanded={expanded}>
                            <Navbar.Toggle
                                aria-controls="basic-navbar-nav"
                                onClick={() => setExpanded(expanded ? false : true)}
                            />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mx-auto">
                                    <Nav.Link as={Link} to="/dashboard" className="header-navbar" onClick={handleNavClick}>
                                        Dashboard
                                    </Nav.Link>
                                    {/* <NavDropdown className="header-navbar" style={{ padding: "0 !important" }} title="Our Services" id="basic-nav-dropdown">
                                        <NavDropdown.Item as={Link} to="/skating-sessions" className="header-navbar nav-dropdown-items" onClick={handleNavClick}>
                                            Skating Sessions
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item as={Link} to="/website-design" className="header-navbar nav-dropdown-items" onClick={handleNavClick}>
                                            Website Design
                                        </NavDropdown.Item>
                                    </NavDropdown> */}
                                    <Nav.Link as={Link} to="/users" className="header-navbar" onClick={handleNavClick}>
                                        Users
                                    </Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                </div>
            </div>
            <div className="the-border-1" />
        </>
    );
}

export default Header;