import React, { useEffect } from "react";
import "../_styles.scss";
import Header from "../components/Header";
import useProtected from "../hooks/useProtected";
import UserPortfolio from "../components/UserPortfolio";
import { useAuth } from "../hooks/useAuth";

const Dashboard = () => {
    useProtected();

    useEffect(() => {
        document.title = "Dashboard";
    }, []);

    const { currentUser, isAdmin } = useAuth();

    return (
        <div className="p-0 m-0 mx-auto justify-content-center">
            {currentUser && isAdmin ? <Header /> : null}
            <UserPortfolio />
        </div>
    );

};

export default Dashboard;
