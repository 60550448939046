import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { doc, getDoc } from "firebase/firestore";
import { sendPasswordResetEmail, getAuth } from "firebase/auth";
import { db } from "../firebase";
import * as yup from "yup";
import VerificationModal from "./VerificationModal";

const UserPasswordChange = () => {
    const { displayName } = useParams();
    const [email, setEmail] = useState("");
    const [verificationModalOpen, setVerificationModalOpen] = useState(false);
    const auth = getAuth();

    useEffect(() => {
        const fetchEmail = async () => {
            const docRef = doc(db, "users", displayName);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setEmail(docSnap.data().email);
            } else {
                toast.error("User not found.");
            }
        };

        fetchEmail();
    }, [displayName]);

    const sendResetEmail = async () => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                toast.success("A password reset link has been sent to your email.");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                // Add error handling logic based on errorCode here
                switch (errorCode) {
                    case "auth/user-not-found":
                        toast.error("No account found with the provided email.");
                        break;
                    case "auth/invalid-email":
                        toast.error("The email address is badly formatted.");
                        break;
                    default:
                        toast.error(errorMessage);
                        break;
                }
            });
    }

    const handlePasswordChange = async (e) => {
        e.preventDefault();

        // Validate the email
        try {
            await yup.object().shape({
                email: yup
                    .string()
                    .email("Must be a valid email")
                    .required("Email is required"),
            }).validate({ email });
        } catch (err) {
            console.error(err.errors);
            toast.error(err.errors[0]);
            return; // stop execution if validation fails
        }

        setVerificationModalOpen(true); // open the verification modal
    };

    return (
        <div className="p-1 p-md-3 m-0 mt-5 col-12 col-md-6 text-start">
            <p>Current email: {email}</p>
            <button onClick={handlePasswordChange}>Change Password</button>
            <VerificationModal
                show={verificationModalOpen}
                onHide={() => setVerificationModalOpen(false)}
                onConfirm={() => {
                    setVerificationModalOpen(false);
                    sendResetEmail();  // send the email after user confirms in the modal
                }}
            />
        </div>
    );
};

export default UserPasswordChange;
