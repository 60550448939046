import React from "react";
import "../_styles.scss";
import { Link, useParams, Outlet } from "react-router-dom";

const UserPage = () => {
    const { displayName } = useParams();

    return (
        <div className="container">
            <h2 className="mx-2">{displayName}'s Page</h2>
            <nav>
                <Link className="btn mx-2" to={`/users`}>Back</Link>
                <Link className="btn mx-2" to={`/users/${displayName}/userTexts`}>Texts</Link>
                <Link className="btn mx-2" to={`/users/${displayName}/userImages`}>Images</Link>
                <Link className="btn mx-2" to={`/users/${displayName}/userGI`}>General Information</Link>
            </nav>
            <Outlet />
        </div>
    );
};

export default UserPage;
