import React, { useState, useEffect } from "react";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { db, functions } from "../firebase";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { httpsCallable } from "firebase/functions";

import VerificationModal from "./VerificationModal";
import AddUserModal from "./AddUserModal";
import "react-toastify/dist/ReactToastify.css";

const UserManagementApp = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [verificationModalOpen, setVerificationModalOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [addUserModalOpen, setAddUserModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [roleDetermined, setRoleDetermined] = useState(false);

    // Function to fetch users from Firebase Firestore
    const fetchUsers = async () => {
        const usersCollectionRef = collection(db, "users");
        const usersQuerySnapshot = await getDocs(usersCollectionRef);

        const data = usersQuerySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        setUsers(data);
        setLoading(false);
    };

    // Function to fetch current user data
    const fetchCurrentUser = () => {
        const auth = getAuth();
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdTokenResult().then((idTokenResult) => {
                    if (!!idTokenResult.claims.admin) {
                        setCurrentUser({ isAdmin: true });
                    } else {
                        setCurrentUser({ isAdmin: false });
                    }
                    setRoleDetermined(true);
                });
            } else {
                setCurrentUser(null);
                setRoleDetermined(true);
            }
        });
    };

    // Function to trigger delete prompt
    const handleDeletePrompt = (user) => {
        setUserToDelete(user);
        setVerificationModalOpen(true);
    };

    // Function to confirm deletion and perform the delete operation
    const handleDeleteConfirm = async () => {
        if (currentUser && currentUser.isAdmin) {
            try {
                setLoading(true);
                const userDocRef = doc(db, "users", userToDelete.id);

                toast.info("Deleting user with ID: " + userToDelete.id);

                await deleteDoc(userDocRef);

                const deleteUserAccount = httpsCallable(functions, "deleteUserAccount");
                const result = await deleteUserAccount({ uid: userToDelete.uid, displayName: userToDelete.displayName });

                if (result.data.error) {
                    throw new Error(result.data.message);
                }

                toast.success("Deleted user account: " + userToDelete.displayName);

                setUsers(users.filter((u) => u.id !== userToDelete.id));
                setLoading(false);
            } catch (error) {
                // Detailed error for the console
                console.error("Detailed Error deleting user: ", error);

                // General error message for the user
                toast.error("Error deleting user: There was an issue deleting this user.");
                setLoading(false);
            } finally {
                setVerificationModalOpen(false);
                setUserToDelete(null);
            }
        } else {
            toast.error("Only admins can delete users");
        }
    };

    useEffect(() => {
        fetchUsers();
        fetchCurrentUser();
    }, []);

    return (
        <div className="p-3 m-0 mt-5 col-12 col-md-6 user-management-app">
            <AddUserModal
                addUserModalOpen={addUserModalOpen}
                setAddUserModalOpen={setAddUserModalOpen}
                currentUser={currentUser}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                fetchUsers={fetchUsers}
            />

            <div>
                <h4 className="mb-3">Users:</h4>
                <ul className="list-group text-start">
                    {users.map((user) => (
                        <li className="list-group-item d-flex align-items-center" key={user.id}>
                            <div className="p-0 m-0 w-100">
                                {user.displayName}
                                {user.isAdmin ? <span className="admin-label"> (Admin) </span> : null}
                                {user.isClient ? <span className="client-label"> (Client) </span> : null}
                            </div>
                            {currentUser && currentUser.isAdmin && (
                                <>
                                    <Link className="m-0 px-3 py-2 blue-btn btn" aria-label="Edit User" to={`/users/${user.displayName}`}>Edit</Link>
                                    <button className="m-0 red-btn btn" onClick={() => handleDeletePrompt(user)} aria-label="Delete User">Delete</button>
                                </>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <VerificationModal
                show={verificationModalOpen}
                onHide={() => setVerificationModalOpen(false)}
                onConfirm={handleDeleteConfirm}
                isLoading={loading}
            />
            {!roleDetermined && (
                <p>Determining your authentication status...</p>
            )}
            {roleDetermined && currentUser && currentUser.isAdmin && (
                <button className="m-0 green-btn btn" aria-label="Add User" onClick={() => setAddUserModalOpen(true)}>Add User</button>
            )}
            {roleDetermined && currentUser && !currentUser.isAdmin && (
                <p>You must be an administrator to create a user.</p>
            )}
            {roleDetermined && !currentUser && (
                <p>You are not authenticated.</p>
            )}
        </div>
    );
};

export default UserManagementApp;
