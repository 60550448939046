import { useEffect } from "react";
import "../_styles.scss";
import Header from "../components/Header";
import UserManagementApp from "../components/UserManagementApp";
import UnbanUsers from "../components/UnbanUsers";
import useProtected from "../hooks/useProtected";

const Users = () => {
    useProtected();

    useEffect(() => {
        document.title = "Users";
    }, []);

    return (
        <div className="p-0 m-0 mx-auto text-center justify-content-center container">
            <Header />
            <div className="p-0 m-0 w-100 row">
                <UserManagementApp />
                <UnbanUsers />
            </div>
        </div>
    );
};

export default Users;
