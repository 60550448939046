import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TagManager from 'react-gtm-module';

// Initialize Google Tag Manager
const tagManagerArgs = {
    gtmId: 'G-0CEDSVKH9Q'
}
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App />
);

// Log web vitals to the console
reportWebVitals(console.log);
