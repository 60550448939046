import React, { useState, useEffect, useRef } from "react";
import {
    auth,
    // sendPasswordResetEmail
} from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import "../_styles.scss";
import { toast } from "react-toastify";
import { getFunctions, httpsCallable } from "firebase/functions";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";
import * as yup from "yup";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isMounted = useRef(true);
    const schema = yup.object().shape({
        email: yup
            .string()
            .email("Must be a valid email")
            .required("Email is required"),
        password: yup
            .string()
            .min(8, "Password must be at least 8 characters")
            .required("Password is required"),
    });

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    // Error Messages
    const displayErrorMessages = (errorCode) => {
        switch (errorCode) {
            case "auth/user-disabled":
                return "This account has been disabled.";
            case "auth/invalid-email":
            case "auth/user-not-found":
            case "auth/wrong-password":
                return "Error: Invalid email or password.";
            default:
                console.error(`Unexpected error: ${errorCode}`);
                return "An unexpected error occurred. Please try again.";
        }
    };

    // FORGOT PASSWORD
    // const handleForgotPassword = async (e) => {
    //     e.preventDefault();

    //     // Validate the email
    //     try {
    //         await yup.object().shape({
    //             email: yup
    //                 .string()
    //                 .email("Must be a valid email")
    //                 .required("Email is required"),
    //         }).validate({ email });
    //     } catch (err) {
    //         console.error(err.errors);
    //         toast.error(err.errors[0]);
    //         return; // stop execution if validation fails
    //     }

    //     sendPasswordResetEmail(auth, email)
    //         .then(() => {
    //             toast.success("If an account exists for the provided email, a password reset link has been sent.");
    //         })
    //         .catch((error) => {
    //             const errorCode = error.code;
    //             const errorMessage = error.message;

    //             // Add error handling logic based on errorCode here
    //             switch (errorCode) {
    //                 case "auth/user-not-found":
    //                     toast.error("If an account exists for the provided email, a password reset link has been sent.");
    //                     break;
    //                 case "auth/invalid-email":
    //                     toast.error("The email address is badly formatted.");
    //                     break;
    //                 default:
    //                     toast.error(errorMessage);
    //                     break;
    //             }
    //         });
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate data
        try {
            await schema.validate({ email, password });
        } catch (err) {
            console.error(err.errors);
            toast.error(err.errors[0]);
            return; // stop execution if validation fails
        }

        setIsLoading(true);

        const getLoginStatus = httpsCallable(getFunctions(auth.app), "getLoginStatus");
        const recordFailedLoginAttempt = httpsCallable(getFunctions(auth.app), "recordFailedLoginAttempt");
        let banCheckResult;

        try {
            // Check if the user is banned before attempting to login
            banCheckResult = await getLoginStatus({});
            if (banCheckResult.data && banCheckResult.data.banned) {
                toast.error(banCheckResult.data.message);
                return;
            }

            // Use the email directly for authentication
            await signInWithEmailAndPassword(auth, email, password);

            toast.success("Login Successful. Welcome " + email);

            // Login successful, reset the attempts
            const resetAttempts = httpsCallable(getFunctions(auth.app), "resetAttempts");
            await resetAttempts({});

        } catch (error) {
            // Increase the attempts after failed login
            banCheckResult = await recordFailedLoginAttempt({});

            // If the user is banned after this failed attempt, inform them and return
            if (banCheckResult.data && banCheckResult.data.banned) {
                toast.error(banCheckResult.data.message);
                return;
            }

            const friendlyErrorMessages = error.code ? displayErrorMessages(error.code) : error.message;
            toast.error(friendlyErrorMessages);
        } finally {
            if (isMounted.current) {
                setIsLoading(false);
            }
        }
    };

    const RenderSpans = ({ count }) => {
        return Array.from({ length: count }, (_, i) => <span key={i}></span>);
    }

    return (
        <section id="login-background">
            <RenderSpans count={200} />

            <form onSubmit={handleSubmit} className="signin" autoComplete="off">
                <div className="content">
                    <h2>Sign In</h2>
                    <div className="form">
                        <div className="inputBox">
                            <input
                                type="text"
                                id="typeEmail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="off"
                                required
                            />
                            <i>Email</i>
                        </div>
                        <div className="inputBox">
                            <input
                                type={
                                    // showPassword ? "text" : 
                                    "password"}
                                id="typePassword"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="new-password"
                                required
                            />
                            <i>Password</i>
                        </div>
                        <button className="inputBox" type="submit" disabled={isLoading}>
                            {isLoading ? (
                                <ReactLoading className="loader" type="spokes" />
                            ) : (
                                "Login"
                            )}
                        </button>
                    </div>
                </div>
            </form>
        </section>

    );
};

export default Login;