import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const initialTimeState = days.reduce((acc, curr) => ({ ...acc, [curr]: { start: "", end: "" } }), {});

const UserOP = () => {
    const { displayName } = useParams();

    const [timeSlots, setTimeSlots] = useState(initialTimeState);
    const [startValue, setStartValue] = useState("");
    const [endValue, setEndValue] = useState("");

    const handleTimeChange = (day, period) => (event) => {
        const { value } = event.target;

        const newTimeSlots = {
            ...timeSlots,
            [day]: { ...timeSlots[day], [period]: value },
        };
        setTimeSlots(newTimeSlots);
    };

    const handleRemoveAll = () => {
        setTimeSlots(initialTimeState);
    };

    const handleStartValueChange = (event) => {
        setStartValue(event.target.value);
    };

    const handleEndValueChange = (event) => {
        setEndValue(event.target.value);
    };

    const handleApplyValues = () => {
        const newTimeSlots = {};
        for (const day of days) {
            newTimeSlots[day] = { start: startValue, end: endValue };
        }
        setTimeSlots(newTimeSlots);
    };

    const handleAllUploads = async () => {
        const hoursDoc = days.reduce((doc, day) => {
            const { start, end } = timeSlots[day];
            if (start !== "" || end !== "") {
                doc[day] = `${formatTimeTo12Hour(start)} - ${formatTimeTo12Hour(end)}`;
            } else {
                doc[day] = "CLOSED";
            }

            return doc;
        }, {});

        const docRef = doc(db, displayName, "operation-hours");
        await setDoc(docRef, hoursDoc);
        toast.success("Operation Hours Uploaded Successfully!");
    };

    const formatTimeTo12Hour = (time) => {
        if (!time) return "";

        const [hour, minute] = time.split(":");
        const formattedTime = parseInt(hour) < 12 ? `${hour}:${minute} AM` : `${hour - 12}:${minute} PM`;

        return formattedTime;
    };

    const formatTimeTo24Hour = (time) => {
        if (!time) return "";

        let [hour, minute] = time.split(":");
        const period = minute.substr(-2);
        minute = minute.substr(0, 2);

        if (period === "PM" && hour !== "12") hour = String(parseInt(hour) + 12);
        else if (period === "AM" && hour === "12") hour = "00";

        return `${hour}:${minute}`;
    };

    // Fetching data when the component loads
    useEffect(() => {
        const fetchData = async () => {
            const docRef = doc(db, displayName, "operation-hours");
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                let data = docSnap.data();
                let updatedTimeSlots = { ...initialTimeState };
                for (const day of days) {
                    if (data[day]) {
                        let times = data[day].split(" - ");
                        if (times.length === 2) {
                            updatedTimeSlots[day] = {
                                start: formatTimeTo24Hour(times[0]),
                                end: formatTimeTo24Hour(times[1]),
                            };
                        }
                    }
                }
                setTimeSlots(updatedTimeSlots);
            }
        };
        fetchData();
        // eslint-disable-line react-hooks/exhaustive-deps
    }, [displayName]);

    return (
        <div className="p-1 p-md-3 m-0 mt-5 col-12 col-md-6 text-start">
            <div className="row">
                <button className="w-50 my-4 blue-btn btn" onClick={handleAllUploads}>
                    Upload All
                </button>
                <button className="w-50 my-4 red-btn btn" onClick={handleRemoveAll}>
                    Remove All
                </button>
            </div>

            <div className="row justify-content-center">
                <label className="m-2 w-auto">Start Time:</label>
                <input
                    className="mx-2 w-auto"
                    type="time"
                    onChange={handleStartValueChange}
                    value={startValue}
                />
                <label className="m-2 w-auto">End Time:</label>
                <input
                    className="mx-2 w-auto"
                    type="time"
                    onChange={handleEndValueChange}
                    value={endValue}
                />
                <button className="btn m-2" onClick={handleApplyValues}>
                    Apply to all
                </button>
            </div>
            {days.map((day) => (
                <div key={day}>
                    <label className="m-2">{day}:</label>
                    <input
                        className="mx-2 w-auto"
                        type="time"
                        id={`${day}-start`}
                        name={`${day}-start`}
                        onChange={handleTimeChange(day, "start")}
                        value={timeSlots[day].start}
                    />
                    -
                    <input
                        className="mx-2 w-auto"
                        type="time"
                        id={`${day}-end`}
                        name={`${day}-end`}
                        onChange={handleTimeChange(day, "end")}
                        value={timeSlots[day].end}
                    />
                </div>
            ))}
        </div>
    );
};

export default UserOP;
