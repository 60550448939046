import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const UsersGIInfo = () => {
    const { displayName } = useParams();

    // State for input fields
    const [initialData, setInitialData] = useState({});
    const [formData, setFormData] = useState({});

    // Fetching data when the component loads
    useEffect(() => {
        const fetchData = async () => {
            const docRef = doc(db, displayName, "usergi");
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                setInitialData(data);
                setFormData(data);
            }
        };

        fetchData();
    }, [displayName]);

    // Function to handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    // Function to handle form submission
    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (JSON.stringify(initialData) === JSON.stringify(formData)) {
            toast.info("No changes to be made.");
            return;
        }

        try {
            const docRef = doc(db, displayName, "usergi");
            await setDoc(docRef, formData);
            toast.success("Information saved successfully!");
        } catch (error) {
            toast.error("An error occurred. Please try again.");
        }
    };

    // Function to handle clearing input fields
    const handleRemoveClick = () => {
        setFormData({});
    };

    return (
        <form className="p-1 p-md-3 m-0 mt-5 col-12 text-start" onSubmit={handleFormSubmit}>
            <div className="row justify-content-center">
                {/* Business Owner's First Name */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business Owner's First Name:</label>
                    <input
                        type="text"
                        name="BOFName"
                        onChange={handleInputChange}
                        value={formData.BOFName || ""}
                    />
                </div>

                {/* Business Owner's Middle Name */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business Owner's Middle Name:</label>
                    <input
                        type="text"
                        name="BOMName"
                        onChange={handleInputChange}
                        value={formData.BOMName || ""}
                    />
                </div>

                {/* Business Owner's Last Name */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business Owner's Last Name:</label>
                    <input
                        type="text"
                        name="BOLName"
                        onChange={handleInputChange}
                        value={formData.BOLName || ""}
                    />
                </div>

                {/* Business Owner's Email */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business Owner's Email:</label>
                    <input
                        type="email"
                        name="BOEmail"
                        onChange={handleInputChange}
                        value={formData.BOEmail || ""}
                    />
                </div>

                {/* Business Owner's Phone1 */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business Owner's Phone1:</label>
                    <input
                        type="tel"
                        name="BOPhone1"
                        onChange={handleInputChange}
                        value={formData.BOPhone1 || ""}
                    />
                </div>

                {/* Business Owner's Phone2 */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business Owner's Phone2:</label>
                    <input
                        type="tel"
                        name="BOPhone2"
                        onChange={handleInputChange}
                        value={formData.BOPhone2 || ""}
                    />
                </div>

                {/* Business' Email */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business' Email:</label>
                    <input
                        type="email"
                        name="BEmail"
                        onChange={handleInputChange}
                        value={formData.BEmail || ""}
                    />
                </div>

                {/* Business' Display Name */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business' Display Name:</label>
                    <input
                        type="text"
                        name="BDName"
                        onChange={handleInputChange}
                        value={formData.BDName || ""}
                    />
                </div>

                {/* Corporate Name */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Corporate Name:</label>
                    <input
                        type="text"
                        name="CName"
                        onChange={handleInputChange}
                        value={formData.CName || ""}
                    />
                </div>

                {/* Corporation Number */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Corporation Number:</label>
                    <input
                        type="text"
                        name="CNum"
                        onChange={handleInputChange}
                        value={formData.CNum || ""}
                    />
                </div>

                {/* Business' Phone1 */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business' Phone1:</label>
                    <input
                        type="tel"
                        name="BPhone1"
                        onChange={handleInputChange}
                        value={formData.BPhone1 || ""}
                    />
                </div>

                {/* Business' Phone2 */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business' Phone2:</label>
                    <input
                        type="tel"
                        name="BPhone2"
                        onChange={handleInputChange}
                        value={formData.BPhone2 || ""}
                    />
                </div>

                {/* Business' Domain */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business' Domain:</label>
                    <input
                        type="text"
                        name="BDomain"
                        onChange={handleInputChange}
                        value={formData.BDomain || ""}
                    />
                </div>

                {/* Business' Address */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business' Address:</label>
                    <input
                        type="text"
                        name="BAddress"
                        onChange={handleInputChange}
                        value={formData.BAddress || ""}
                    />
                </div>

                {/* Business' Postal Code */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business' Postal Code:</label>
                    <input
                        type="text"
                        name="BPostal"
                        onChange={handleInputChange}
                        value={formData.BPostal || ""}
                    />
                </div>

                {/* Business' Province/State */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business' Province/State:</label>
                    <input
                        type="text"
                        name="BProvince"
                        onChange={handleInputChange}
                        value={formData.BProvince || ""}
                    />
                </div>

                {/* Business' Country */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business' Country:</label>
                    <input
                        type="text"
                        name="BCountry"
                        onChange={handleInputChange}
                        value={formData.BCountry || ""}
                    />
                </div>

                {/* Business' City */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business' City:</label>
                    <input
                        type="text"
                        name="BCity"
                        onChange={handleInputChange}
                        value={formData.BCity || ""}
                    />
                </div>

                {/* Business' Map Url */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Business' Map Url:</label>
                    <input
                        type="text"
                        name="BMapUrl"
                        onChange={handleInputChange}
                        value={formData.BMapUrl || ""}
                    />
                </div>

                {/* Instagram */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Instagram:</label>
                    <input
                        type="text"
                        name="Instagram"
                        onChange={handleInputChange}
                        value={formData.Instagram || ""}
                    />
                </div>

                {/* Facebook */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Facebook:</label>
                    <input
                        type="text"
                        name="Facebook"
                        onChange={handleInputChange}
                        value={formData.Facebook || ""}
                    />
                </div>

                {/* WhatsApp */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>WhatsApp:</label>
                    <input
                        type="text"
                        name="WhatsApp"
                        onChange={handleInputChange}
                        value={formData.WhatsApp || ""}
                    />
                </div>

                {/* LinkedIn */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>LinkedIn:</label>
                    <input
                        type="text"
                        name="LinkedIn"
                        onChange={handleInputChange}
                        value={formData.LinkedIn || ""}
                    />
                </div>

                {/* Telegram */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>Telegram:</label>
                    <input
                        type="text"
                        name="Telegram"
                        onChange={handleInputChange}
                        value={formData.Telegram || ""}
                    />
                </div>

                {/* YouTube */}
                <div className="p-2 m-0 col-6 col-md-3">
                    <label>YouTube:</label>
                    <input
                        type="text"
                        name="YouTube"
                        onChange={handleInputChange}
                        value={formData.YouTube || ""}
                    />
                </div>

                {/* Remove Button */}
                <button type="button" className="w-50 my-4 red-btn btn" onClick={handleRemoveClick}>
                    Remove
                </button>

                {/* Submit Button */}
                <button type="submit" className="w-50 my-4 blue-btn btn">
                    Save Information
                </button>
            </div>
        </form>
    );
};

export default UsersGIInfo;
