import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { toast } from 'react-toastify';
import { useAuth } from "../hooks/useAuth";

const EditPortfolioModal = ({ show, handleClose, currentData, handleEdit }) => {
    const [mainTitle, setMainTitle] = useState('');
    const [mainDesc, setMainDesc] = useState('');
    const { currentUser } = useAuth();

    useEffect(() => {
        setMainTitle(currentData?.mainTitle || '');
        setMainDesc(currentData?.mainDesc || '');
    }, [currentData]);

    const handleSave = async () => {
        if (!mainTitle || !mainDesc) {
            toast.error("Error: missing data needed to update document.");
            return;
        }

        const docRef = doc(db, currentUser.displayName, mainTitle);

        try {
            // Only update the mainTitle and mainDesc, other fields remain untouched
            let updatedData = {
                ...currentData,
                mainTitle: mainTitle,
                mainDesc: mainDesc
            };

            // Remove undefined fields
            Object.keys(updatedData).forEach(key => updatedData[key] === undefined ? delete updatedData[key] : {});

            await setDoc(docRef, updatedData, { merge: true });
            toast.success("Document updated successfully!");
            handleEdit(currentData.id, updatedData);
            handleClose();  // No need to pass the updated data back to the parent
        } catch (error) {
            console.error("Error updating document:", error);
            toast.error(`Error updating document: ${error.message}`);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Portfolio Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" value={mainTitle} disabled />
                </Form.Group>
                <div className="p-0 col-12 quill-editor">
                    <ReactQuill theme="snow" value={mainDesc} onChange={setMainDesc} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditPortfolioModal;
