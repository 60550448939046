import React, { useEffect, useState, useCallback, useRef } from "react";
import "../_styles.scss";
import { doc, setDoc, getDocs, collection } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from "firebase/storage";
import { db } from "../firebase";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Modal from "react-bootstrap/Modal";
import ReactLoading from "react-loading";
import imageCompression from "browser-image-compression";
import TagManager from "react-gtm-module";
import FlipMove from "react-flip-move";
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill } from "react-icons/bs";
import VerificationModal from "./VerificationModal";

const AddPortfolioModal = ({ show, handleClose, setDisplayName, setDocsData }) => {
    const [mainTitle, setMainTitle] = useState("");
    const [mainDesc, setMainDesc] = useState("");
    const [files, setFiles] = useState([]);
    const { currentUser } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const inputFileRef = useRef();
    const [rawTitle, setRawTitle] = useState("");
    const [verificationModalOpen, setVerificationModalOpen] = useState(false);
    const [isPreviewLoading, setIsPreviewLoading] = useState(false);
    const [inputKey, setInputKey] = useState(Date.now());

    useEffect(() => {
        const displayName = currentUser.displayName || currentUser.email;
        setDisplayName(displayName);
    }, [currentUser, setDisplayName]);

    const handleTitleChange = (value) => {
        // Save raw input
        setRawTitle(value);

        // Clean up for database
        const cleanValue = value.replace(/[^a-zA-Z0-9 _]/g, "").replace(/\r?\n|\r/g, " ");
        const trimmedValue = cleanValue;

        setMainTitle(trimmedValue);
    };
    const handleDescChange = (value) => setMainDesc(value);

    const moveFileUp = (index) => {
        setFiles((prevFiles) => {
            const newFiles = [...prevFiles];  // Clone the array
            // Swap the file with the one before it
            [newFiles[index - 1], newFiles[index]] = [newFiles[index], newFiles[index - 1]];
            return newFiles;
        });
    };

    const moveFileDown = (index) => {
        setFiles((prevFiles) => {
            const newFiles = [...prevFiles];  // Clone the array
            // Swap the file with the one after it
            [newFiles[index], newFiles[index + 1]] = [newFiles[index + 1], newFiles[index]];
            return newFiles;
        });
    };

    const handleFileChange = async (e) => {
        setIsPreviewLoading(true);
        const newFiles = Array.from(e.target.files);
        const compressedFiles = [];

        // Check for duplicate files
        for (let newFile of newFiles) {
            for (let file of files) {
                if (file.name === newFile.name) {
                    toast.error("Duplicate file error: You cannot upload files with the same name.");
                    e.target.value = ""; // Clear the file input field
                    setIsPreviewLoading(false);
                    return;
                }
            }

            if (newFile.type.startsWith("image/")) {
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true,
                };
                try {
                    const compressedFile = await imageCompression(newFile, options);
                    compressedFiles.push(compressedFile);
                } catch (error) {
                    console.log(error);
                }
            } else {
                compressedFiles.push(newFile);
            }
        }

        setFiles((prevFiles) => [...prevFiles, ...compressedFiles]);
        setIsPreviewLoading(false);
    };

    // Just open the confirmation modal here. Don't do the deletion yet.
    const handleFileRemoval = (index) => {
        setVerificationModalOpen(index);
    };

    const handleDeleteConfirm = () => {
        // The index to remove is stored in verificationModalOpen
        const index = verificationModalOpen;

        // If the first file is removed, check if the next file is an image. If not, remove it too
        if (index === 0 && files.length > 1 && !files[1].type.startsWith("image/")) {
            toast.error("The first file must be an image!");
            setFiles((prevFiles) => prevFiles.slice(2));
        } else {
            setFiles((prevFiles) => prevFiles.filter((_, idx) => idx !== index));
        }

        // Update the key for file input
        setInputKey(Date.now());

        // Close the verification modal
        setVerificationModalOpen(false);
    };

    // Uploads file and retrieves download URL
    const uploadFileAndGetURL = useCallback(async (uploadTask, index, file) => {
        return new Promise((resolve, reject) => {
            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log("Upload is " + progress + "% done");
                },
                (error) => {
                    console.error("Upload failed:", error);
                    reject(error);
                },
                async () => {
                    try {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        console.log("File uploaded successfully:", downloadURL);
                        resolve({
                            [`fileName${index}`]: file.name,
                            [`fileUrl${index}`]: downloadURL
                        });
                    } catch (error) {
                        console.error("Error while getting download URL:", error);
                        reject(error);
                    }
                }
            );
        });
    }, []);

    // Handles upload for both image and video files
    const uploadAllFilesAndSetDoc = useCallback(async (docId, updatedDoc, docRef) => {
        const displayName = currentUser.displayName || currentUser.email;
        const storage = getStorage();
        const filePromises = files.map((file, index) => {
            const fileName = `${docId}/files/${file.name}`;
            const storageRef = ref(storage, `${displayName}/${fileName}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            return uploadFileAndGetURL(uploadTask, index, file);
        });

        try {
            const fileDatas = await Promise.all(filePromises);
            fileDatas.forEach((data) => {
                Object.assign(updatedDoc, data);
            });

            await setDoc(docRef, updatedDoc);
            resetInputFields();
            toast.success("Texts and Files uploaded successfully!")

            // Add new document to docsData
            setDocsData((prevDocsData) => [...prevDocsData, { id: docId, ...updatedDoc }]);

            // Close the modal
            handleClose();
        } catch (error) {
            toast.error("Error updating document or uploading files:", error)
            console.error("Error updating document or uploading files:", error);
        }
    }, [currentUser, files, handleClose, setDocsData, uploadFileAndGetURL]);

    const handleUploadAll = useCallback(async () => {
        // Prevent upload if the first file is a video
        if (files[0] && files[0].type.startsWith("video/")) {
            toast.error("The first file cannot be a video.");
            return;
        }

        setIsLoading(true);

        TagManager.dataLayer({
            dataLayer: {
                event: 'click_event',
                category: 'Button',
                action: 'Click',
                label: 'Add Portfolio Item',
            }
        });

        const displayName = currentUser.displayName || currentUser.email;
        const validations = [
            {
                isInvalid: mainTitle.trim() === "",
                error: "Title is required!"
            },
            {
                isInvalid: !displayName || displayName.trim() === "",
                error: "Username is empty!"
            },
        ];

        try {
            for (const { isInvalid, error } of validations) {
                if (isInvalid) {
                    throw new Error(error);
                }
            }

            const userCollectionRef = collection(db, displayName);
            const userDocsSnapshot = await getDocs(userCollectionRef);
            const titleExists = userDocsSnapshot.docs.some((doc) => doc.data().mainTitle === mainTitle);

            if (titleExists) {
                throw new Error("A document with the same title already exists!");
            }

            const docId = mainTitle.trim() !== "" ? mainTitle.replace(/<[^>]*>?/gm, "") : Date.now().toString();
            const docRef = doc(db, displayName, docId);

            // Strip the HTML tags from the mainTitle and mainDesc before saving
            const updatedDoc = {
                mainTitle: mainTitle,
                mainDesc: mainDesc
            };

            await uploadAllFilesAndSetDoc(docId, updatedDoc, docRef);
            if (inputFileRef.current) {
                inputFileRef.current.value = "";
            }
        } catch (error) {
            toast.error(error.message);
            console.error(error);
            if (inputFileRef.current) {
                inputFileRef.current.value = "";
            }
        } finally {
            setIsLoading(false);
        }
    }, [files, mainTitle, mainDesc, currentUser, uploadAllFilesAndSetDoc]);

    const resetInputFields = () => {
        setMainTitle("");
        setMainDesc("");
        setFiles([]); // Reset file input
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Portfolio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2>Main Title</h2>
                <div className="p-0 col-12 quill-editor">
                    <input
                        value={mainTitle}
                        onChange={e => handleTitleChange(e.target.value)}
                        style={{ whiteSpace: "pre" }}
                    />
                </div>
                {rawTitle.match(/[^a-zA-Z0-9 _]/) && (
                    <p style={{ color: "red" }}>
                        Invalid characters detected in title. Only alphanumeric characters, spaces, and underscores are allowed.
                    </p>
                )}
                <h2 className="mt-3">Main Description</h2>
                <div className="p-0 col-12 quill-editor">
                    <ReactQuill theme="snow" value={mainDesc} onChange={handleDescChange} />
                </div>
                <h2 className="mt-3">Files</h2>
                <p style={{ color: "red", marginBottom: "1em" }}>
                    Please reduce the size of your files before uploading to decrease loading times, minimize storage use, and improve overall performance. You can use any compression website like <a target="_blank" style={{ color: "#3498db", fontWeight: "bold", textDecoration: "underline" }} href="https://www.freeconvert.com/video-compressor" rel="noopener noreferrer" >freeconvert</a> for this.
                </p>
                <p style={{ color: "green", marginBottom: "1em" }}>
                    The first image will be your portfolio cover!
                </p>
                <input
                    type="file"
                    id="file-input"
                    multiple
                    accept="image/*,video/*"
                    onChange={handleFileChange}
                    key={inputKey} // Add this line
                    style={{ display: "none" }} // Hide the actual input
                    disabled={isPreviewLoading || isLoading}  // Disable the input if either isPreviewLoading or isLoading is true
                />
                <label htmlFor="file-input" className={isPreviewLoading || isLoading ? "blue-btn btn disabled" : "blue-btn btn"}>{isPreviewLoading ? <ReactLoading className="loader" type="spokes" /> : "Upload Files"}</label>
                <FlipMove typeName={null}>
                    {files.map((file, index) => (
                        <div className="file-container" key={file.name}>
                            <div className="file-box">
                                {file.type.startsWith("image/") ?
                                    <img className="file-content" src={URL.createObjectURL(file)} alt="" /> :
                                    <video className="file-content" src={URL.createObjectURL(file)} controls />}
                            </div>
                            <div className="file-buttons"> {/* place buttons into their own div */}
                                <button
                                    className={isPreviewLoading || isLoading ? "m-0 w-50 h-50 blue-btn btn disabled" : "m-0 w-50 h-50 blue-btn btn"}
                                    onClick={() => moveFileUp(index)}
                                    disabled={index === 0}
                                >
                                    <BsFillArrowUpCircleFill />
                                </button>
                                <button
                                    className={isPreviewLoading || isLoading ? "m-0 w-50 h-50 blue-btn btn disabled" : "m-0 w-50 h-50 blue-btn btn"}
                                    onClick={() => moveFileDown(index)}
                                    disabled={index === files.length - 1}
                                >
                                    <BsFillArrowDownCircleFill />
                                </button>
                                <button className={isPreviewLoading || isLoading ? "m-0 w-100 h-50 red-btn btn disabled" : "m-0 w-100 h-50 red-btn btn"} onClick={() => handleFileRemoval(index)} disabled={isPreviewLoading || isLoading}>Remove</button>
                            </div>
                        </div>
                    ))}
                </FlipMove>
            </Modal.Body>
            <Modal.Footer>
                <button className="red-btn btn" onClick={handleClose}>
                    Close
                </button>
                <button className={isPreviewLoading || isLoading ? "green-btn btn disabled" : "green-btn btn"} onClick={handleUploadAll} disabled={isPreviewLoading || isLoading}>
                    {isLoading ? <ReactLoading className="loader" type="spokes" /> : "Upload All"}
                </button>
            </Modal.Footer>
            <VerificationModal
                show={verificationModalOpen !== false}
                onHide={() => setVerificationModalOpen(false)}
                onConfirm={handleDeleteConfirm}
                isLoading={isLoading}
            />
        </Modal>
    );
};

export default AddPortfolioModal;