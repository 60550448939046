import React, { useEffect, useCallback } from "react";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const LogoutButton = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = useCallback(async () => {
        await logout();
        navigate("/");
        localStorage.removeItem("loginTimestamp"); // Remove the timestamp from local storage on logout
        toast.success("You have successfully logged out.");
    }, [logout, navigate]);

    useEffect(() => {
        const loginTimestamp = localStorage.getItem("loginTimestamp");
        if (!loginTimestamp) {
            // User just logged in
            localStorage.setItem("loginTimestamp", Date.now());
        } else {
            // User not just logged in. Check if time exceeded
            const elapsedTime = Date.now() - loginTimestamp;
            if (elapsedTime > 3600000) { // 1 hour in milliseconds
                handleLogout();
            }
        }

        // Also check every second if the time limit is reached
        const logoutTimer = setInterval(() => {
            const elapsedTime = Date.now() - localStorage.getItem("loginTimestamp");
            if (elapsedTime > 3600000) { // 1 hour in milliseconds
                handleLogout();
            }
        }, 1000); // 1 second

        return () => clearInterval(logoutTimer); // Clear the interval when the component is unmounted
    }, [handleLogout]);

    return (
        <div className="logout-btn btn">
            <button className="red-btn btn" onClick={handleLogout}>
                Logout
            </button>
        </div>
    );
};

export default LogoutButton;
