import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { db } from "../firebase";
import useProtected from "../hooks/useProtected";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const UserTexts = () => {
    useProtected(); // Custom hook for authentication
    const { displayName } = useParams();
    const [pages, setPages] = useState(Array.from({ length: 8 }, () => ({ texts: ["", "", "", "", "", "", "", ""] })));

    // Fetch document from Firestore
    const fetchDocument = useCallback(async (pageName) => {
        const docRef = doc(db, displayName, pageName);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            return { id: docSnapshot.id, ...docSnapshot.data() };
        }
        return null;
    }, [displayName]);

    // Fetch all the options when the component is mounted
    useEffect(() => {
        const fetchOptions = async () => {
            const fetchedPages = [];
            for (let i = 0; i < pages.length; i++) {
                const pageDoc = await fetchDocument(`page-${i}`);
                if (pageDoc) {
                    const texts = pageDoc.texts || {};
                    const page = {
                        texts: [
                            texts[0] || "",
                            texts[1] || "",
                            texts[2] || "",
                            texts[3] || "",
                            texts[4] || "",
                            texts[5] || "",
                            texts[6] || "",
                            texts[7] || "",
                        ],
                    };
                    fetchedPages.push(page);
                } else {
                    fetchedPages.push({ texts: ["", "", "", "", "", "", "", ""] });
                }
            }
            setPages(fetchedPages);
        };

        fetchOptions();
    }, [displayName, fetchDocument, pages.length]);

    const NUM_TEXTS_PER_PAGE = 8;

    // Event handler for text change
    const handleTextChange = useCallback((pageIndex, textIndex) => (value) => {
        setPages((prevPages) => {
            const newPages = [...prevPages];
            // Replace <p><br></p> with an empty string
            newPages[pageIndex].texts[textIndex] = value === "<p><br></p>" ? "" : value;
            return newPages;
        });
    }, []);

    // Event handler for text submit
    const handleTextSubmit = useCallback(async (pageIndex) => {
        const page = pages[pageIndex];
        const pageRef = doc(db, displayName, `page-${pageIndex}`);

        try {
            const docSnapshot = await getDoc(pageRef);
            if (docSnapshot.exists()) {
                const existingData = docSnapshot.data();
                const updatedDoc = {
                    ...existingData,
                    texts: {
                        0: page.texts[0] || "",  // Use empty string if value is falsy
                        1: page.texts[1] || "",
                        2: page.texts[2] || "",
                        3: page.texts[3] || "",
                        4: page.texts[4] || "",
                        5: page.texts[5] || "",
                        6: page.texts[6] || "",
                        7: page.texts[7] || "",

                    },
                };
                await setDoc(pageRef, updatedDoc);
            } else {
                const newDoc = {
                    texts: {
                        0: page.texts[0] || "",
                        1: page.texts[1] || "",
                        2: page.texts[2] || "",
                        3: page.texts[3] || "",
                        4: page.texts[4] || "",
                        5: page.texts[5] || "",
                        6: page.texts[6] || "",
                        7: page.texts[7] || "",
                    },
                };
                await setDoc(pageRef, newDoc);
            }
        } catch (error) {
            console.error("Error updating document:", error);
        }
    }, [pages, displayName]);

    // Event handler for all uploads
    const handleAllUploads = useCallback(async () => {
        let hasChanges = false;
        for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
            const page = pages[pageIndex];
            let pageHasChanges = false;
            try {
                const pageDoc = await fetchDocument(`page-${pageIndex}`);
                const currentTexts = pageDoc ? pageDoc.texts || {} : {};
                for (let textIndex = 0; textIndex < NUM_TEXTS_PER_PAGE; textIndex++) {
                    const currentText = currentTexts[textIndex] || "";
                    const newText = page.texts[textIndex];
                    if (newText !== currentText) {
                        pageHasChanges = true;
                        break;
                    }
                }
                if (!pageHasChanges) {
                    continue;
                }

                await handleTextSubmit(pageIndex);
                hasChanges = true;
            } catch (error) {
                console.error("Error uploading document:", error);
            }
        }

        if (hasChanges) {
            toast.success("All Texts Uploaded Successfully!");
        } else {
            toast.info("No changes to upload.");
        }
    }, [pages, fetchDocument, handleTextSubmit]);

    return (
        <div className="container">
            <button className="w-100 my-4 blue-btn btn" onClick={handleAllUploads}>Upload All</button>
            {pages.map((page, pageIndex) => (
                <div key={pageIndex} className="row">
                    <div className="p-0 m-0 w-100 row">
                        <h2>Page {pageIndex}</h2>
                        {page.texts.map((text, textIndex) => (
                            <div key={textIndex} className="p-0 py-3 p-sm-3 m-0 col-12 col-lg-6 row">
                                <h4>Text {textIndex}</h4>
                                <div className="p-0 col-12 quill-editor">
                                    <ReactQuill theme="snow" value={text} onChange={handleTextChange(pageIndex, textIndex)} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default UserTexts;
