import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";
import VerificationModal from "./VerificationModal";
import { fetchBannedIPs } from "../utils/api";
import ReactLoading from "react-loading";

const UnbanUsers = () => {
    const [bannedIPs, setBannedIPs] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [verificationModalOpen, setVerificationModalOpen] = useState(false);
    const [ipToUnban, setIpToUnban] = useState(null);
    const [unbanLoading, setUnbanLoading] = useState(null);
    const functions = getFunctions();
    const resetAttemptsCallable = httpsCallable(functions, "resetAttempts");

    const fetchCurrentUser = () => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdTokenResult().then((idTokenResult) => {
                    if (!!idTokenResult.claims.admin) {
                        setCurrentUser({ isAdmin: true });
                    } else {
                        setCurrentUser({ isAdmin: false });
                    }
                }).catch(() => {
                    toast.error("Failed to verify user role. Please try again.");
                });
            } else {
                setCurrentUser(null);
            }
        });
    };

    const fetchBannedIPsFromAPI = async () => {
        try {
            const response = await fetchBannedIPs();
            const { data } = response;
            setBannedIPs(data);
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBannedIPsFromAPI();
        fetchCurrentUser();
    }, []);

    const unbanIP = async (ip) => {
        if (!currentUser || !currentUser.isAdmin) {
            toast.error("Only admins can unban users");
            return;
        }

        if (!ip) {
            toast.error("No IP address provided.");
            return;
        }

        setUnbanLoading(ip);

        try {
            await resetAttemptsCallable({ ip });

            setBannedIPs((prevIPs) => prevIPs.filter((ipInfo) => ipInfo.ip !== ip));

            toast.success(`IP address ${ip} has been unbanned successfully.`);
            fetchBannedIPsFromAPI(); // Updated to correctly fetch and update state of banned IPs
        } catch {
            toast.error(`Failed to unban IP address ${ip}. Please try again.`);
        } finally {
            setUnbanLoading(null);
        }
    };

    const handleUnbanPrompt = (ip) => {
        setIpToUnban(ip);
        setVerificationModalOpen(true);
    };

    const handleUnbanConfirm = () => {
        if (ipToUnban) {
            unbanIP(ipToUnban);
            setIpToUnban(null);
        }
        setVerificationModalOpen(false);
    };

    return (
        <div className="p-3 m-0 mt-5 col-12 col-md-6">
            <VerificationModal
                show={verificationModalOpen}
                onHide={() => setVerificationModalOpen(false)}
                onConfirm={handleUnbanConfirm}
            />
            <div className="banned-ips">
                <h4 className="mb-3">Banned IP Addresses:</h4>
                {loading ? (
                    <ReactLoading className="loader" type="spokes" />
                ) : (
                    <>
                        {bannedIPs.length === 0 ? (
                            <p className="mt-4">No banned IPs to display.</p>
                        ) : (
                            <ul className="list-group text-start">
                                {bannedIPs.map((ipInfo) => (
                                    <li className="list-group-item d-flex align-items-center" key={ipInfo.ip}>
                                        <div className="p-0 m-0 w-100">
                                            {ipInfo.ip}
                                        </div>
                                        {currentUser && currentUser.isAdmin && (
                                            unbanLoading === ipInfo.ip ? (
                                                <ReactLoading className="loader" type="spokes" />
                                            ) : (
                                                <button onClick={() => handleUnbanPrompt(ipInfo.ip)} className="m-0 red-btn btn unban-button">
                                                    Unban
                                                </button>
                                            )
                                        )}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default UnbanUsers;
