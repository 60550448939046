import React, { useState, useEffect, useRef, createRef } from "react";
import { useParams } from "react-router-dom";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { db } from "../firebase";
import imageCompression from "browser-image-compression";

const UserImages = () => {
    const { displayName } = useParams();
    const [pages, setPages] = useState(Array.from({ length: 8 }, () => ({
        images: [null, null, null, null, null, null, null, null],
        imageUrls: ["", "", "", "", "", "", "", ""],
        imageNames: ["", "", "", "", "", "", "", ""],
        isDeleted: [false, false, false, false],
    })));
    const fileInputRefs = useRef(Array.from({ length: 8 }, () => Array.from({ length: 8 }, () => createRef())));
    const [previewActive, setPreviewActive] = useState(Array.from({ length: 8 }, () => Array.from({ length: 8 }, () => false)));

    useEffect(() => {
        const fetchImages = async () => {
            for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
                const pageRef = doc(db, displayName, `page-${pageIndex}`);
                const pageDoc = await getDoc(pageRef);

                if (pageDoc.exists()) {
                    const pageData = pageDoc.data();

                    setPages((prevPages) => {
                        const newPages = [...prevPages];
                        for (let imageIndex = 0; imageIndex < 8; imageIndex++) {
                            newPages[pageIndex].imageUrls[imageIndex] = pageData[`imageUrl${imageIndex}`] || "";
                            newPages[pageIndex].imageNames[imageIndex] = pageData[`imageName${imageIndex}`] || "";
                        }
                        return newPages;
                    });
                }
            }
        };

        fetchImages();
    }, [pages.length, displayName]);

    // Fetch image URL from Firebase storage
    const getImageUrl = async (pageIndex, imageIndex) => {
        const page = pages[pageIndex];
        const imageName = page.imageNames[imageIndex];
        if (imageName) {
            const storage = getStorage();
            const storageRef = ref(storage, `${displayName}/page-${pageIndex}/images/${imageName}`);
            return await getDownloadURL(storageRef);
        }
        return "";
    };

    // Event handler for image change (when a new file is chosen)
    const handleImageChange = (pageIndex, imageIndex) => (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        setPages((prevPages) => {
            const newPages = [...prevPages];
            newPages[pageIndex].images[imageIndex] = file; // Update the correct image
            newPages[pageIndex].isDeleted[imageIndex] = false;
            return newPages;
        });
        setPreviewActive((prevState) => {
            const newPreviewState = [...prevState];
            newPreviewState[pageIndex][imageIndex] = true;
            return newPreviewState;
        });
    };

    // Event handler for image delete
    const handleImageDelete = (pageIndex, imageIndex) => () => {
        setPages((prevPages) => {
            const newPages = [...prevPages];
            newPages[pageIndex].isDeleted[imageIndex] = true;
            return newPages;
        });
    };

    // Event handler for image retrieval (brings back the previously deleted image)
    const handleImageRetrieve = (pageIndex, imageIndex) => async () => {
        const newPages = [...pages];
        newPages[pageIndex].isDeleted[imageIndex] = false;

        if (newPages[pageIndex].images[imageIndex]) {
            const imageUrl = await getImageUrl(pageIndex, imageIndex);
            newPages[pageIndex].imageUrls[imageIndex] = imageUrl;
        } else if (newPages[pageIndex].imageUrls[imageIndex]) {
            const imageUrl = newPages[pageIndex].imageUrls[imageIndex];
            newPages[pageIndex].imageUrls[imageIndex] = imageUrl;
        }

        setPages(newPages);
    };

    const handleImageRemoval = (pageIndex, imageIndex) => () => {
        if (previewActive[pageIndex][imageIndex]) {
            setPages((prevPages) => {
                const newPages = [...prevPages];
                newPages[pageIndex].images[imageIndex] = null;
                return newPages;
            });
            setPreviewActive((prevState) => {
                const newPreviewState = [...prevState];
                newPreviewState[pageIndex][imageIndex] = false;
                return newPreviewState;
            });
            fileInputRefs.current[pageIndex][imageIndex].current.value = null; // Clear file input value
        }
    };

    // Event handler for image upload
    const handleImageUpload = async (pageIndex, imageIndex) => {
        let page = pages[pageIndex];
        let image = page.images[imageIndex];
        const imageName = image ? image.name : null;

        if (image && imageName) {
            // Image compression options
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            };

            try {
                // Only compress if the image is not SVG
                if (image.type !== "image/svg+xml") {
                    // Compress image
                    image = await imageCompression(image, options);
                }
            } catch (error) {
                console.log("Error occurred while compressing image: ", error);
            }

            const storage = getStorage();
            const storageRef = ref(
                storage,
                `${displayName}/page-${pageIndex}/images/${imageName}`
            );

            // Check if the image with the same name already exists in the storage
            // If it does, delete it
            if (page.imageNames[imageIndex]) {
                const oldImageRef = ref(
                    storage,
                    `${displayName}/page-${pageIndex}/images/${page.imageNames[imageIndex]}`
                );
                try {
                    await deleteObject(oldImageRef);
                } catch (error) {
                    console.error("Error deleting old image:", error);
                }
            }

            // Continue with the image upload as before
            const uploadTask = uploadBytesResumable(storageRef, image);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // You can add code here to handle the progress of the upload
                },
                (error) => {
                    console.error("Error uploading file:", error);
                },
                async () => {
                    try {
                        const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);

                        if (imageUrl) {
                            setPages((prevPages) => {
                                const newPages = [...prevPages];
                                newPages[pageIndex].imageUrls[imageIndex] = imageUrl;
                                newPages[pageIndex].imageNames[imageIndex] = imageName;
                                return newPages;
                            });

                            const pageRef = doc(db, displayName, `page-${pageIndex}`);

                            await setDoc(pageRef, {
                                [`imageUrl${imageIndex}`]: imageUrl,
                                [`imageName${imageIndex}`]: imageName,
                            }, { merge: true });
                        }
                    } catch (error) {
                        console.error("Error updating Firestore document:", error);
                    }
                }
            );
        } else {
            console.log("Invalid image or image name");
        }
    };

    // Event handler for deleting image from Firebase storage
    const handleImageDeleteFromStorage = async (pageIndex, imageIndex) => {
        const page = pages[pageIndex];
        if (page.isDeleted[imageIndex] && page.imageNames[imageIndex]) {
            const imageName = page.imageNames[imageIndex];
            const storage = getStorage();
            const storageRef = ref(
                storage,
                `${displayName}/page-${pageIndex}/images/${imageName}`
            );

            try {
                const pageRef = doc(db, displayName, `page-${pageIndex}`);
                const snapshot = await getDoc(pageRef);
                const data = snapshot.data();

                // Check if any other images in the same Firestore document have the same image name
                const hasOtherImagesWithSameName = Object.keys(data).some((key) => {
                    if (key.startsWith("imageName") && key !== `imageName${imageIndex}`) {
                        return data[key] === imageName;
                    }
                    return false;
                });

                if (!hasOtherImagesWithSameName) {
                    // Delete the image from storage
                    await deleteObject(storageRef);
                }

                // Delete the image details from Firestore
                const imageUrlKey = `imageUrl${imageIndex}`;
                const imageNameKey = `imageName${imageIndex}`;

                await updateDoc(pageRef, {
                    [imageUrlKey]: "",
                    [imageNameKey]: "",
                });

                setPages((prevPages) => {
                    const newPages = [...prevPages];
                    newPages[pageIndex].imageUrls[imageIndex] = "";
                    newPages[pageIndex].images[imageIndex] = null;
                    newPages[pageIndex].imageNames[imageIndex] = "";
                    return newPages;
                });

            } catch (error) {
                console.error("Error deleting image:", error);
            }
        }
    };

    // Function to delete image details from Firestore
    const handleImageDeleteFromFirestore = async (pageIndex, imageIndex) => {
        const page = pages[pageIndex];
        if (!page.imageUrls[imageIndex]) {
            return; // Skip deletion if the image URL is already empty
        }

        const pageRef = doc(db, displayName, `page-${pageIndex}`);
        await updateDoc(pageRef, {
            [`imageUrl${imageIndex}`]: "",
            [`imageName${imageIndex}`]: "",
        });

        setPages((prevPages) => {
            const newPages = [...prevPages];
            newPages[pageIndex].imageUrls[imageIndex] = "";
            newPages[pageIndex].imageNames[imageIndex] = "";
            return newPages;
        });
    };

    // Event handler for all uploads
    const handleAllUploads = async () => {
        let hasChanges = false;
        for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
            const page = pages[pageIndex];
            for (let imageIndex = 0; imageIndex < 8; imageIndex++) {
                if (page.images[imageIndex] && !page.isDeleted[imageIndex]) {
                    await handleImageUpload(pageIndex, imageIndex);
                    hasChanges = true;
                } else if (page.isDeleted[imageIndex] && page.imageNames[imageIndex]) {
                    await handleImageDeleteFromStorage(pageIndex, imageIndex);
                    await handleImageDeleteFromFirestore(pageIndex, imageIndex);
                    hasChanges = true;
                }
            }
            // Clear the file input value after processing each page
            for (let imageIndex = 0; imageIndex < 8; imageIndex++) {
                fileInputRefs.current[pageIndex][imageIndex].current.value = null;
            }
            // Call the remove button for all images
            setPages((prevPages) => {
                const newPages = [...prevPages];
                newPages[pageIndex].images = [null, null, null, null, null, null, null, null];
                newPages[pageIndex].isDeleted = [false, false, false, false, false, false, false, false];
                return newPages;
            });
            // Clear the image preview state
            setPreviewActive((prevState) => {
                const newPreviewState = [...prevState];
                newPreviewState[pageIndex] = Array.from({ length: 8 }, () => false);
                return newPreviewState;
            });
        }

        if (hasChanges) {
            toast.success("All operations completed successfully!");
        } else {
            toast.info("No changes to make.");
        }
    };

    return (
        <div className="container">
            <button className="w-100 my-4 blue-btn btn" onClick={handleAllUploads}>Upload All</button>
            {pages.map((page, pageIndex) => (
                <div key={pageIndex} className="row">
                    <div className="p-0 m-0 w-100 row">
                        <h2>Page {pageIndex}</h2>
                        {page.images.map((image, imageIndex) => (
                            <div key={imageIndex} className="p-0 p-sm-3 m-0 col-12 col-lg-6 row">
                                <div className="p-0 m-0 w-100 row">
                                    <input
                                        className="w-75 rounded-0"
                                        type="file"
                                        onChange={handleImageChange(pageIndex, imageIndex)}
                                        ref={fileInputRefs.current[pageIndex][imageIndex]}
                                    />
                                    <button
                                        className={`w-25 btn ${previewActive[pageIndex][imageIndex] && image ? "red-btn" : "grey-btn "}`}
                                        onClick={handleImageRemoval(pageIndex, imageIndex)}
                                    >
                                        Remove
                                    </button>
                                </div>
                                <br />
                                <div className="db-div-position" style={image || page.imageUrls[imageIndex] ? { backgroundImage: "none" } : { backgroundImage: "url('/images/placeholder-16-9.jpg')" }}>
                                    {image ? (
                                        <img className="db-image-position" src={URL.createObjectURL(image)} alt="" />
                                    ) : (
                                        <React.Fragment>
                                            {!page.isDeleted[imageIndex] && page.imageUrls[imageIndex] && (
                                                <img className="db-image-position" src={page.imageUrls[imageIndex]} alt="" />
                                            )}
                                            {(image || page.imageUrls[imageIndex]) && (
                                                <button
                                                    className={`db-image-btn btn ${page.isDeleted[imageIndex] ? "grey-btn" : "red-btn"}`}
                                                    onClick={page.isDeleted[imageIndex] ? handleImageRetrieve(pageIndex, imageIndex) : handleImageDelete(pageIndex, imageIndex)}
                                                >
                                                    {page.isDeleted[imageIndex] ? "Retrieve Image" : "Delete Image"}
                                                </button>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default UserImages;
