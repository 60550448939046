import React from "react";
import "../_styles.scss";
import useProtected from "../hooks/useProtected";
import UserOP from "./UserOP";
import UserPasswordChange from "./UserPasswordChange";
import UsersGIInfo from "./UsersGI_Info";

const UsersGI = () => {
    useProtected();

    return (
        <div className="p-0 m-0 mx-auto text-center justify-content-center container">
            <div className="p-0 m-0 w-100 row">
                <UserOP />
                <UserPasswordChange />
                <UsersGIInfo />
            </div>
        </div>
    );
};

export default UsersGI;
