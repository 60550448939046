import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
    Navigate,
    Outlet,
} from "react-router-dom";
import "./_styles.scss";

import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import UserPage from "./pages/UserPage";
import UserTexts from "./components/UserTexts";
import UserImages from "./components/UserImages";
import UsersGI from "./components/UsersGI";
import Login from "./pages/Login";
import Error404 from "./pages/Error404";
import LogoutButton from "./components/LogoutButton";
import { AuthProvider, useAuth } from "./hooks/useAuth";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ConditionalLogoutButton() {
    const { currentUser } = useAuth();
    const location = useLocation();

    if ((location.pathname !== "/" && location.pathname !== "/404") && currentUser) {
        return <LogoutButton />;
    }

    return null;
}

function ProtectedRoute() {
    const { currentUser } = useAuth();

    if (!currentUser) {
        return <Navigate to="/" />;
    }

    return <Outlet />;
}

function AdminRoute() {
    const { currentUser, isAdmin } = useAuth();

    if (!currentUser || !isAdmin) {
        return <Navigate to="/dashboard" />;
    }

    return <Outlet />;
}

function AuthenticatedRoute() {
    const { currentUser } = useAuth();

    if (currentUser) {
        return <Navigate to="/dashboard" />;
    }

    return <Outlet />;
}

function App() {
    function ScrollToTop() {
        const { pathname } = useLocation();

        React.useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);

        return null;
    }

    return (
        <Router>
            <ScrollToTop />
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<AuthenticatedRoute />}>
                        <Route index element={<Login />} />
                    </Route>
                    <Route path="/dashboard" element={<ProtectedRoute />}>
                        <Route index element={<Dashboard />} />
                    </Route>
                    <Route path="/users" element={<AdminRoute />}>
                        <Route index element={<Users />} />
                        <Route path=":displayName" element={<UserPage />}>
                            <Route path="userTexts" element={<UserTexts />} />
                            <Route path="userImages" element={<UserImages />} />
                            <Route path="userGI" element={<UsersGI />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<Error404 />} />
                </Routes>
                <ConditionalLogoutButton />
            </AuthProvider>
            <ToastContainer position="top-center" />
        </Router>
    );
}

export default App;
